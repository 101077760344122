<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="900px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    center
  >
    <div :id="mymapId" :class="myuuid"></div>
    <!-- <p class="cont-title" v-if="long && lat">所选经度为：<span class="color-blue">{{ long }}</span>，纬度为： <span class="color-blue">{{ lat }}</span></p> -->
    <div class="forms-btns" slot="footer">
      <el-button type="primary" size="small" @click="sure">确定</el-button>
      <el-button @click="closeModel" size="small">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { utils } from '@/utils/baseUtils'
  // import AMap from 'AMap'
  export default {
    name: "getamap",
    props:{
      
    },
    data() {
      return {
        title: '选择经纬度',
        closeFlag: false,
        long: '',
        lat: '',
        addr: '',
        mymapId: '',
        myuuid: '',
        initlongitude: '', 
        initlatitude : ''
      }
    },
    mounted() {
      this.mymapId = 'my-map-container'
      this.myuuid = this.getUuid()
    },
    methods: {
      getUuid() {
        return 'my' + ('xxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 8 | 0, 
            v = c == 'x' ? r : (r & 0x3 | 0x8)
          return v.toString(8)
        }));
      },
      setId(){
        let dom = document.querySelectorAll('#my-map-container'),
          len = dom.length
        if(len){
          for(let i = 0; i < len; i++){
            let className = dom[i].className
            if(className != this.myuuid){
              dom[i].setAttribute('id', 'my-map-container1')
            }
          }
        }
        document.querySelector('.' + this.myuuid) && document.querySelector('.' + this.myuuid).setAttribute('id', 'my-map-container')
      },
      init() {
        let map = new AMap.Map(document.querySelector('#' + this.mymapId), {
          resizeEnable: true
        })
        AMap.plugin(['AMap.ToolBar', 'AMap.OverView', 'AMap.MapType', 'AMap.Scale', 'AMap.Geolocation', 'AMap.Autocomplete', 'AMap.PlaceSearch'], function () { //异步加载插件
          // let geolocation = new AMap.Geolocation({
          //   // 是否使用高精度定位，默认：true
          //   enableHighAccuracy: true,
          //   // 设置定位超时时间，默认：无穷大
          //   timeout: 10000,
          //   // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
          //   buttonOffset: new AMap.Pixel(10, 20),
          //   // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          //   zoomToAccuracy: true,
          //   // 定位按钮的排放位置, RB表示右下
          //   buttonPosition: 'RB',
          //   showMarker: true,
          //   showCircle: true,
          // })
          // map.addControl(new AMap.ToolBar());
          // map.addControl(new AMap.OverView());
          // map.addControl(new AMap.MapType());
          // map.addControl(new AMap.Scale());
          // map.addControl(new AMap.Autocomplete());
          // map.addControl(new AMap.PlaceSearch());
          // map.addControl(geolocation);
          // geolocation.getCurrentPosition();
          // AMap.event.addListener(geolocation, 'complete', (e) => {
          //   let marker = new AMap.Marker({
          //     position: [e.position.lng, e.position.lat], // （e.position）--->定位点的点坐标, position ---> marker的定位点坐标，也就是marker最终显示在那个点上，
          //   })
          //   map.add(marker);
          // })
        })

        let marker;

        function regeocoder(lnglatXY, that) {
          AMap.plugin('AMap.Geocoder', function () {
            let geocoder = new AMap.Geocoder({
              radius: 1000,
              extensions: "all"
            });
            geocoder.getAddress(lnglatXY, function (status, result) {
              
              if (status === 'complete' && result.info === 'OK') {
                let address = result.regeocode.formattedAddress;
                that.addr = address
              }
            });
            if (!marker) {
              marker = new AMap.Marker();
              map.add(marker);
            }
            marker.setPosition(lnglatXY);
          })
        }
        let that = this
        map.on('click', function (e) {
          let lnglatXY = [e.lnglat.getLng(), e.lnglat.getLat()];
          regeocoder(lnglatXY, that)
          that.long = utils.getFixedNumber(e.lnglat.getLng())
          that.lat = utils.getFixedNumber(e.lnglat.getLat())
        });
        that.initlongitude && regeocoder([that.initlongitude, that.initlatitude], that)
      },
      sure(){
        if(this.long && this.lat){
          this.$emit('getamap', {
            long: this.long,
            lat: this.lat,
            address: this.addr
          })
          this.closeModel()
        }else{
          this.message('请选择', "warning")
        }
      },
      closeModel(){
        this.closeFlag = false
      },
      initData(longitude, latitude){
        this.initlongitude = longitude
        this.initlatitude = latitude
        this.closeFlag = true
        this.setId()
        setTimeout(() => {
          this.init()
        }, 100)
      }
    }
  }
</script>

<style scoped>
.my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
  #my-map-container {
    height: 680px;
    margin: 0 0 20px 0;
  }
  .cont-title{
    text-align: center;
    font-size: 16px;
    padding-bottom: 10px;
  }
  .color-blue{
    color: blue
  }
</style>