<template>
  <el-dialog
    class="my-dialog1"
    title="选择地址"
    width="600px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    center
  >
    <div v-loading="loading" element-loading-text="请求中">
      <div class="btns" v-if="showBtn == 'two2'">
        <el-button type="primary" size="small" @click="selfCountry">国内</el-button>
        <el-button type="primary" size="small" @click="foreign">境外</el-button>
      </div>
      <div class="btns" v-if="showBtn == 'two3'">
        <el-button type="primary" size="small" @click="selfProvince">本省</el-button>
        <el-button type="primary" size="small" @click="selfCountry">外省</el-button>
        <el-button type="primary" size="small" @click="foreign">境外</el-button>
      </div>
      <div v-if="!sureBtnAlltime" class="color-red text-center">必填到乡镇，即区域位置至少精确到乡/镇/街道</div>
      <div class="search-addr">
        <el-input v-if="type == 'foreign'" class="my-input-btn" size="small" v-model="searchWord" placeholder="请输入关键字">
          <el-button slot="append" @click="searchAddr" type="primary">搜索地址</el-button>
        </el-input>
      </div>
        
      <div class="pick-address-addresse">
        <el-tabs v-model="dataIndex" @tab-click="rePickAddress" class="pick-add-top">
          <el-tab-pane v-for="(item, index) in selectData" :key="index" :label="item.areaName || item.placeHolder" :name="index + ''"></el-tab-pane>
        </el-tabs>
        <div class="location-icon"><el-button size="small" type="primary" icon="el-icon-location-outline" @click="chooseType('getamap')">定位</el-button></div>
      </div>
      <div class="pick-address-list" v-if="selectAddress.length">
        <p v-for="item in selectAddress" :key="item.areaId" :class="item.areaName == selAddrName ? 'pick-address-item-sel pick-address-item' : 'pick-address-item'" @click="pickAddress(item.areaCode, item.areaName, item.level, item.areaId)">{{ item.areaName }}</p>
      </div>
      <div class="no-data" v-else>暂无数据</div>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" size="small" v-if="showSureBtn" @click="sureBtn">确定</el-button>
      <el-button @click="closeModel" size="small">取消</el-button>
    </div>
    <getamap ref="getamap" @getamap="getamap"></getamap>
  </el-dialog>
</template>
<script>
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  import getamap from '@/views/common/getamap.vue'
  export default {
    name: 'pickArea',
    components: {
      getamap
    },
    props:{
      showBtn: {
        type: String,
        default: 'two2',
      },
      useUserAddr: {
        type: Boolean,
        default: false,
      },
      needRequest: {
        type: Boolean,
        default: false,
      },
      sureBtnAlltime: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        closeFlag: false,
        loading: false,
        address: [],
        placeHolders: ['省份', '地级市', '区/县', '乡/镇/街', '村/社区'],
        allAddress: {}, //所有地址
        selectAddress: [], //地址列表
        selectData: [],//选择的地址
        dataIndex: 0, //当前点击index
        initPId: '000000', //初始化pid
        initFPId: '00', //初始化pid
        showSureBtn: false,
        type: 'self',
        loading: false,
        selAddrName: '',
        searchWord: '',
        sureAreaCode: ['710000', '820000', '810000']
      }
    },
    mounted() {
      let _this = this
      if(this.needRequest){
        utils.setStore('allAddress', [])
      }
      if(this.sureBtnAlltime){
        this.showSureBtn = true
      }
      _this.allAddress = utils.getStore('allAddress') || []
      if(_this.useUserAddr){
        _this.setData({
          address: utils.getAddresslist()
        })
      }
      this.resetAllDatas()
    },
    methods: {
      searchAddr(){
        let val = this.searchWord,
          allAdrs = utils.getStore('allAddress') || [],
          arr = utils.filterOne(allAdrs[this.initFPId], val)
        this.setData({
          selectAddress: arr || []
        })
      },
      setData(data){
        let _this = this
        for(let prop in data){
          if(prop == 'dataIndex'){
            _this[prop] = data[prop] + ''
          }else{
            _this[prop] = data[prop]
          }
        }
      },
      resetAllDatas(){
        let _this = this, 
            len = _this.address.length || 0, 
            allAddress = _this.allAddress,
            initPId = _this.initPId
        if(!allAddress[initPId]){
          _this.getData(initPId, initPId, len ? 'hasValue' : 'list')
        }else if(!len){
          _this.addNewSelect(initPId, initPId, 'list')
          _this.setData({
            'selectAddress': allAddress[initPId] || []
          })
        }else{
          _this.initAddress()
        }
      },
      async initAddress(){
        let _this = this, 
          address = _this.address || [], 
          initPId = _this.initPId,
          len = address.length || 0,
          pid = null
        _this.loading = true
        for(let i = 0; i < address.length; i++){
          if(!address[i].areaCode && !address[i].areaName){
            address.splice(i, 1)
            i--
          }
        }
        if(len > 0){
          if(len == 4 || len == 5){
            await utils.requestAddress(address[2].areaCode)
            len == 5 && (await utils.requestAddress(address[3].areaCode))
            address = utils.getAddresslist(address) || []
            _this.setData({
              allAddress: utils.getStore('allAddress')
            })
          }else{
            address = utils.getAddresslist(address) || []
          }
          len = address.length || 0
          pid = address[len - 1].pId
          if(this.sureAreaCode.includes(address[len - 1].areaCode) || address[len - 1].areaCode == '00' || (address[len - 1].level != '02' && address[len - 1].level != '03' && address[len - 1].level != '04')){
            this.setData({
              showSureBtn: true
            })
          }
        }
        let allAddress = _this.allAddress
        if(pid && allAddress[pid]){
          _this.setData({
            selectAddress: allAddress[pid] || []
          })
        }else{
          if(!len){
            _this.getData(initPId, initPId)
          }
        }
        _this.setData({
          dataIndex: len > 0 ?  len - 1 : 0,
          selectData: address
        })
        if(address.length){
          _this.selAddrName = address[address.length - 1].areaName || ''
          if(address[0].level == '00'){
            _this.type = 'foreign'
          }
        }
        _this.loading = false
      },
      getData(pid, areaCode, from, addFlag = false) {
        let _this = this
        _this.loading = true
        request.commonareastree(areaCode == '000000' ? '' : areaCode).then(res => {
          _this.loading = false
          if(res.code == 200){
            if(res.data){
              let lastData = res.data
              _this.allAddress = {
                ..._this.allAddress,
                ...lastData
              }
              utils.setStore('allAddress', _this.allAddress)
              _this.setData({
                selectAddress: _this.allAddress[pid] || []
              })
              if(from == 'hasValue'){
                _this.initAddress()
              }else if(from && from != 'hasValue'){
                _this.addNewSelect(pid, areaCode, from, addFlag)
              }
            }else{
              _this.setData({
                selectAddress: []
              })
              if(from && from != 'hasValue'){
                _this.addNewSelect(pid, areaCode, from, addFlag)
              }
            }
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          _this.loading = false
          console.log(e)
        })
      },
      reGetData(areaCode) {
        let _this = this
        request.commonareastree(areaCode).then(res => {
          if(res.code == 200){
            if(res.data){
              let lastData = res.data
              _this.allAddress = {
                ..._this.allAddress,
                ...lastData
              }
              utils.setStore('allAddress', _this.allAddress)
              _this.setData({
                selectAddress: _this.allAddress[pid] || []
              })
              from && _this.addNewSelect(pid, areaCode, from, addFlag)
            }
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      clearSelect(){
        let selectData = this.selectData || []
        let oldSelectData = selectData.map(item => {
          return {
            ...item,
            selected: false
          }
        })
        oldSelectData[this.dataIndex].selected = true
        this.setData({
          selectData: oldSelectData
        })
      },
      addNewSelect(pid, pcode, from, addFlag = false){
        let _this = this,
          selectData = _this.selectData || [],
          len = selectData.length || 0,
          index = this.dataIndex
        if(index != len){
          selectData = selectData.slice(0, parseInt(index) + 1)
        }
        let areaName = from == 'foreign' ? '国家' : this.placeHolders[selectData.length || 0] || '请选择'
        let obj = {
          areaName: areaName,
          areaCode: '',
          level: '',
          pId: pid,
          pCode: pcode,
          selected: true
        }
        selectData = selectData.map(item => {
          return {
            ...item,
            selected: false
          }
        })
        selectData.push(obj)
        if(addFlag) {
          this.setData({
            dataIndex: parseInt(this.dataIndex) + 1
          })
        }
        _this.setData({
          selectData: selectData,
        })
      },
      async rePickAddress(e){
        let index = e.index, data = this.selectData[index], pid = data.pId
        this.selAddrName = data.areaName
        this.setData({
          dataIndex: index
        })
        this.clearSelect()
        if(this.allAddress[pid]){
          this.setData({
            selectAddress: this.allAddress[pid] || []
          })
        }else{
          this.getData(pid, data.areaCode)
        }
      },
      pickAddress(areacode, areaname, level, areaid){
        let index = this.dataIndex
        this.selectData[index].areaCode = areacode
        this.selectData[index].areaName = areaname
        this.selectData[index].level = level
        this.selAddrName = areaname
        
        if(this.sureBtnAlltime || this.sureAreaCode.includes(areacode)){
          this.setData({
            showSureBtn: true
          })
        }else{
          if(level == '02' || level == '03' || level == '04'){
            this.setData({
              showSureBtn: false
            })
          }else{
            this.setData({
              showSureBtn: true
            })
          }
        }
        if(level == '06' || level == '00'){
          return
        }
        if(this.sureAreaCode.includes(areacode)){
          let arr = this.selectData[0]
          this.selectData = [arr]
          return
        }
        if(this.allAddress[areaid]){
          this.setData({
            selectAddress: this.allAddress[areaid] || []
          })
          this.addNewSelect(areaid, areacode)
          this.setData({
            dataIndex: parseInt(this.dataIndex) + 1
          })
        }else{
          this.getData(areaid, areacode, 'list', true)
        }
        
      },
      selfProvince(){
        let initData = this.initPId
        this.type = 'self'
        this.selAddrName = ''
        let address = utils.getAddresslist()
        if(address.length){
          let arr = [address[0]]
          arr.push({
            areaName: this.placeHolders[1],
            areaCode: '',
            level: '',
            pId: arr[0].id,
            pCode: arr[0].areaCode,
            selected: true
          })
          if(this.allAddress[arr[0].id]){
            this.setData({
              dataIndex: 1,
              selectAddress: this.allAddress[arr[0].id] || [],
              selectData: arr
            })
          }else{
            this.setData({
              dataIndex: 0,
              selectData: [arr[0]],
              selectAddress: this.allAddress[initData] || [],
            })
            this.getData(initData, initData, 'list')
          }
        }else{
          this.selfCountry()
        }
      },
      getMyAddressArr(){
        let myaddress = utils.getStore('userInfo')?.bodyAreaInfo|| {},
          arr = [{
            areaName: myaddress.province || '',
            areaCode: myaddress.provinceCode || '',
          },{
            areaName: myaddress.city || '',
            areaCode: myaddress.cityCode || '',
          },{
            areaName: myaddress.county || '',
            areaCode: myaddress.countyCode || '',
          },{
            areaName: myaddress.town || '',
            areaCode: myaddress.townCode || '',
          },{
            areaName: myaddress.village || '',
            areaCode: myaddress.villageCode || '',
          }], realArr = []
        arr.forEach(cur => {
          if(cur.areaCode){
            realArr.push(cur)
          }
        })
        return realArr
      },
      getMyAddList(){
        let _this = this,
          address = _this.getMyAddressArr(),
          allAddress = _this.allAddress,
          initPId = _this.initPId,
          arr = []
        address.forEach((cur, index) => {
          for(let item in allAddress){
            let curI = allAddress[item]
            if(curI && curI.length){
              curI.forEach(sec => {
                if(cur.areaCode == sec.areaCode){
                  arr.push({
                    pId: sec.areaId,
                    pCode: cur.areaCode,
                    level: cur.level
                  })
                }
              })
            }
          }
        })
        arr.unshift({
          pId: initPId,
          pCode: initPId
        })
        address = address.map((item, index) => {
          return {
            ...item,
            ...arr[index],
            selected: (len - 1 == index) ? true : false
          }
        })
        return address
      },
      selfCountry(){
        this.type = 'country'
        this.selAddrName = ''
        let initData = this.initPId
        this.placeHolders[0] = '省份'
        this.setData({
          showSureBtn: this.sureBtnAlltime ? true : false
        })
        this.refreshAddress(initData)
      },
      foreign(){
        this.type = 'foreign'
        this.selAddrName = ''
        this.searchWord = ''
        let initData = this.initFPId
        this.placeHolders[0] = '国家'
        this.setData({
          showSureBtn: true
        })
        this.refreshAddress(initData)
      },
      refreshAddress(initData, index = 0){
        this.setData({
          dataIndex: index,
          selectAddress: this.allAddress[initData] || [],
          selectData: [{
            areaName: this.placeHolders[index],
            areaCode: '',
            level: '',
            pId: initData,
            pCode: initData,
            selected: true
          }]
        })
        if(this.allAddress[initData]){
          this.setData({
            selectAddress:this.allAddress[initData] || []
          })
        }else{
          this.getData(initData, initData)
        }
      },
      sureBtn(){
        let data = this.selectData || [],
        arr = []
        data.forEach(cur => {
          if(cur.areaCode){
            arr.push(cur)
          }
        })
        if(this.type == 'foreign' && !arr.length){
          arr = [{
            areaCode: this.initFPId,
            areaName: '',
          }]
        }
        this.$emit('getpickarea', arr)
        this.closeModel()
      },
      resetAddress(address){
        this.selectData = []
        this.selectAddress = []
        this.dataIndex = '0'
        this.address = address || []
        this.resetAllDatas()
      },
      initData(address){
        this.closeFlag = true
        if(address){
          this.resetAddress(address)
        }
      },
      chooseType(name){
        this.$refs[name].initData()
      },
      getamap(data){
        let _this = this
        _this.loading = true
        request.getByAddress({
          address: data.address || ''
        }).then(res => {
          _this.loading = false
          if(res.code == 200){
            let addObj = utils.getAddressByObj(res.data)
            _this.resetAddress(addObj)
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          _this.loading = false
          console.log(e)
        })
      },
      closeModel() {
        this.closeFlag = false
      },
    },
    watch: {
      // 'defaultAddress': {
      //   handler(val){
      //     this.ruleForm.smpOrgid = val
      //     this.queryData()
      //   },
      //   deep: true
      // }
    },
    beforeUpdate() {},
    beforeMount() {},
  }
</script>
<style lang="scss" scoped>
  .my-upload ::v-deep .el-upload--picture-card{
    width: 88px;
    height: 88px;
    line-height: 98px;
  }
  .my-dialog1 ::v-deep .el-dialog--center .el-dialog__body{
    padding: 0 20px;
  }
  .my-dialog1 ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
  .pick-address-list{
    height: 450px;
    overflow: auto;
  }
  .pick-address-item{
    border: 1px solid #efebeb;
    background-color: #f3f1f1;
    padding: 10px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }
  .pick-address-item-sel,
  .pick-address-item:hover{
    border: 1px solid #409eff ;
    background-color: #409eff ;
    color: #fff;
  }
  .no-data{
    text-align: center;
    height: 460px;
    padding: 20px;
  }
  .btns{
    text-align: center;
    padding: 20px;
  }
  .pick-address-addresse{
    display: flex;
    align-items: center;
  }
  .pick-add-top{
    width: 90%;
  }
  .location-icon{
    margin-left: 10px;
  }
  .search-addr{
    padding: 10px 0;
  }
</style>
